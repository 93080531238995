import {Flex, Skeleton} from '@polysky-libs/uikit'
import Balance from 'components/Balance'
import {FontSize} from 'constants/Theme'
import {useTranslation} from 'contexts/Localization'
import React from 'react'
import {Pool} from 'state/types'
import styled from 'styled-components'

const MultiplierRow: React.FC<Pool> = ({pool}) => {
  const {t} = useTranslation()
  const {depositFee, isFinished} = pool

  return (
    <Wrapper>
      <Text>{`${t('Deposit Fee:')}`}</Text>
      {isFinished || (!depositFee && depositFee !== 0) ? (
        <Skeleton width="82px" height="22px" />
      ) : (
        <Flex alignItems="center">
          <Balance fontSize="16px" isDisabled={isFinished} value={depositFee} decimals={2} unit="%" bold />
        </Flex>
      )}
    </Wrapper>
  )
}

export default MultiplierRow

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  div:last-child {
    margin-left: 0.3rem;
  }
`

const Text = styled.p`
  color: ${(props) => props.theme.theme.PRIMARY_BACKGROUND_COLOR};
  font-size: ${FontSize.mobil}rem;

  @media (min-width: 768px) {
    font-size: ${FontSize.tab}rem;
  }
`
