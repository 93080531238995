import BigNumber from 'bignumber.js'
import fetchFarmsPrices from 'state/farms/fetchFarmsPrices'
import {Farm} from 'state/types'
import {getAddress} from 'utils/addressHelpers'

const getPriceFromTokenFarm = (farms, address) => {
  const farm = farms.find((f: Farm) => getAddress(f.token.address) === address && f.token.usdcPrice)
  return !farm ? null : farm.token.usdcPrice
}
const getPriceFromQuoteTokenFarm = (farms, address) => {
  const farm = farms.find((f: Farm) => getAddress(f.quoteToken.address) === address && f.quoteToken.usdcPrice)
  return !farm ? null : farm.quoteToken.usdcPrice
}

const getPrice = (farms, address) => {
  const tokenPrice = getPriceFromTokenFarm(farms, address)
  if (tokenPrice) {
    return tokenPrice
  }
  return getPriceFromQuoteTokenFarm(farms, address)
}

const fetchVaultsPrices = async (vaults, farms) => {
  const farmPrices = await fetchFarmsPrices(farms)

  const vaultsWithPrices = vaults.map((vault) => {
    let baseTokenPrice = getPrice(farmPrices, getAddress(vault.token.address))
    let quoteTokenPrice = getPrice(farmPrices, getAddress(vault.quoteToken.address))
    const rewardTokenPrice = getPrice(farmPrices, getAddress(vault.rewardToken.address))

    if (baseTokenPrice && (!quoteTokenPrice || quoteTokenPrice === '0')) {
      quoteTokenPrice = baseTokenPrice.times(vault.tokenAmountTotal).div(vault.quoteTokenAmountTotal)
    }
    if ((!baseTokenPrice || baseTokenPrice === '0') && quoteTokenPrice) {
      baseTokenPrice = new BigNumber(quoteTokenPrice)
        .times(new BigNumber(vault.quoteTokenAmountTotal))
        .div(vault.tokenAmountTotal)
        .toJSON()
    }

    const token = {...vault.token, usdcPrice: baseTokenPrice}
    const quoteToken = {...vault.quoteToken, usdcPrice: quoteTokenPrice}
    const rewardToken = {...vault.rewardToken, usdcPrice: rewardTokenPrice}
    return {...vault, token, quoteToken, rewardToken}
  })

  return vaultsWithPrices
}

export default fetchVaultsPrices
