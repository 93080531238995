import {ResetCSS} from '@polysky-libs/uikit'
import BigNumber from 'bignumber.js'
import {DatePickerPortal} from 'components/DatePicker'
import useEagerConnect from 'hooks/useEagerConnect'
import React, {lazy} from 'react'
import {Route, Router, Switch} from 'react-router-dom'
// import { usePollCoreFarmData,usePollCoreVaultData, usePollBlockNumber } from 'state/hooks'
import styled from 'styled-components'
import PageLoader from './components/PageLoader'
import SuspenseWithChunkError from './components/SuspenseWithChunkError'
import {ToastListener} from './contexts/ToastsContext'
import history from './routerHistory'
import GlobalStyle from './style/Global'
// import EasterEgg from './components/EasterEgg'
import Pools from './views/Pools'

// Route-based code splitting
// Only pool is included in the main bundle because of it's the most visited page
const Home = lazy(() => import('./views/Home'))
const Farms = lazy(() => import('./views/Farms'))
const Vaults = lazy(() => import('./views/Vaults'))
const More = lazy(() => import('./views/More'))
const FarmAuction = lazy(() => import('./views/FarmAuction'))
const NotFound = lazy(() => import('./views/NotFound'))
const Layout = lazy(() => import('./components/template/Layout'))
const SideBar = lazy(() => import('./components/template/sidebar/index'))
const Header = lazy(() => import('./components/ui/molecule/header/index'))
const Content = lazy(() => import('./components/ui/organisms/container/index'))

// This config is required for number formatting
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

export interface AppRoute {
  path: string
  title: string
  exact?: boolean
  component: React.ReactNode
}

const AppRoutes: AppRoute[] = [
  {
    path: '/',
    title: 'Home',
    exact: true,
    component: <Home />,
  },
  {
    path: '/vault',
    title: 'Vaults',
    component: <Vaults />,
  },
  {
    path: '/pool',
    title: 'Pools',
    component: <Pools />,
  },
  {
    path: '/farm',
    title: 'Farms',
    component: <Farms />,
  },
  {
    path: '/setting',
    title: 'More',
    component: <More />,
  },
]

const App: React.FC = () => {
  //  usePollBlockNumber()
  useEagerConnect()
  //  usePollCoreFarmData()
  //  usePollCoreVaultData()

  return (
    <Router history={history}>
      <ResetCSS />
      <GlobalStyle />
      <div>
        <SuspenseWithChunkError fallback={<PageLoader />}>
          <Switch>
            <Layout>
              <SideBar />
              <Content>
                <Header />
                <ContentWrapper>
                  <Routes />
                </ContentWrapper>
              </Content>
            </Layout>
          </Switch>
        </SuspenseWithChunkError>
      </div>
      <ToastListener />
      <DatePickerPortal />
    </Router>
  )
}

export default React.memo(App)

const Routes = () => {
  return (
    <Switch>
      {AppRoutes.map((route) => (
        <Route exact={route.exact} path={route.path}>
          {route.component}
        </Route>
      ))}
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  )
}

const ContentWrapper = styled.div`
  padding-top: 80px;
  padding: 100px 0.4rem 4rem;

  @media (min-width: 1100px) {
    padding: 100px 1rem 4rem;
  }
`
