import {TextProps} from '@polysky-libs/uikit'
import {FontSize} from 'constants/Theme'
import React, {useEffect, useRef} from 'react'
import CountUp from 'react-countup'
import styled from 'styled-components'

interface BalanceProps extends TextProps {
  value: number
  decimals?: number
  unit?: string
  isDisabled?: boolean
  prefix?: string
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
}

const Balance: React.FC<BalanceProps> = ({
  value,
  color = 'text',
  decimals = 3,
  isDisabled = false,
  unit,
  prefix,
  onClick,
  ...props
}) => {
  const previousValue = useRef(0)

  useEffect(() => {
    previousValue.current = value
  }, [value])

  return (
    <ARP color={isDisabled ? 'textDisabled' : color} onClick={onClick} {...props}>
      {prefix && <span>{prefix}</span>}
      <CountUp start={previousValue.current} end={value} decimals={decimals} duration={1} separator="," />
      {unit && <span>{unit}</span>}
    </ARP>
  )
}

export default Balance

const isPool = window.location.href.includes('/pool')
const ARP = styled.p`
  color: ${(props) => props.theme.theme.PRIMARY_TEXT_COLOR};
  font-size: ${FontSize.mobil}rem;
  & + & {
    margin-right: 0.625rem;
  }

  @media (min-width: 768px) {
    font-size: ${FontSize.tab}rem;
  }
`
