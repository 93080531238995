import {createGlobalStyle} from 'styled-components'
// eslint-disable-next-line import/no-unresolved
import {PancakeTheme} from '@polysky-libs/uikit/dist/theme'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`


@font-face {
  font-family: Graphik;
  src: url('../assets/fonts/Graphik-Regular-Web.woff2');
  font-weight: 400;
}

@font-face {
  font-family: 'Graphik-m5';
  src: url('../assets/fonts/Graphik-Regular-Web.woff2');
  font-weight: 500;
}

@font-face {
  font-family: 'Graphik-m6';
  src: url('../assets/fonts/Graphik-Medium-Web.woff2');
  font-weight: 600;
}

@font-face {
  font-family: 'Graphik-m7';
  src: url('../assets/fonts/Graphik-Semibold.woff');
  font-weight: 700;
}

@font-face {
  font-family: 'Graphik-m8';
  src: url('../assets/fonts/Graphik-Bold.woff');
  font-weight: 800;
}

@font-face {
  font-family: 'Graphik-large';
  src: url('../assets/fonts/Graphik-Bold.woff');
  font-weight: 900;
}



  * {
    font-family: Graphik, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    ::-webkit-scrollbar-thumb{background:#0052FF;border-radius:8px;}
  }



body {
  margin: 0;
  font-family: Graphik, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

    /* background-color: ${({theme}) => theme.colors.background}; */
    img {
      height: auto;
      max-width: 100%;
    }

    
}

`

export default GlobalStyle
