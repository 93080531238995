import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {darkTheme} from '../../constants/Theme'

const initialState = {
  theme: {...darkTheme},
}

// const themeReducer = (state= initialState, action: any) => {
//     switch(action.type){
//         case SWITCH_THEME:
//             return { theme: action.theme };

//             default:
//                 return state;
//     }
// };

const themeReducer = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setTheme: (state, action: PayloadAction<any>) => {
      // eslint-disable-next-line no-param-reassign
      state.theme = action.payload
    },
  },
})

// Actions
export const {setTheme} = themeReducer.actions
export default themeReducer.reducer
