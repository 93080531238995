import {Skeleton} from '@polysky-libs/uikit'
import Balance from 'components/Balance'
import {BASE_EXCHANGE_URL} from 'config'
import {FontSize} from 'constants/Theme'
import {useTranslation} from 'contexts/Localization'
import React from 'react'
import {Pool} from 'state/types'
import styled from 'styled-components'
import {getAddress} from 'utils/addressHelpers'
import {getAprData} from 'views/Pools/helpers'

interface AprRowProps {
  pool: Pool
  performanceFee?: number
}

const AprRow: React.FC<AprRowProps> = ({pool, performanceFee = 0}) => {
  const {t} = useTranslation()
  const {stakingToken, isFinished, apr, isAutoVault} = pool

  //  const { targetRef, tooltip, tooltipVisible } = useTooltip(tooltipContent, { placement: 'bottom-start' })

  const {apr: earningsPercentageToDisplay} = getAprData(pool, performanceFee)

  const apyModalLink =
    stakingToken.address && `${BASE_EXCHANGE_URL}/swap?outputCurrency=${getAddress(stakingToken.address)}`

  return (
    <Wrapper>
      <Text style={{lineHeight: '0.1'}}>{isAutoVault ? `${t('APY')}:` : `${t('APR')}:`}</Text>
      {isFinished || !apr ? (
        <Skeleton width="62px" height="18px" />
      ) : (
        <Balance
          fontSize="16px"
          isDisabled={isFinished}
          value={earningsPercentageToDisplay}
          decimals={2}
          unit="%"
          bold
        />
      )}
    </Wrapper>
  )
}

export default AprRow

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  div:last-child {
    margin-left: 0.3rem;
  }
`

const Text = styled.p`
  color: ${(props) => props.theme.theme.PRIMARY_BACKGROUND_COLOR};
  font-size: ${FontSize.mobil}rem;

  @media (min-width: 768px) {
    font-size: ${FontSize.tab}rem;
  }
`
