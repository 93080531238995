import {useWalletModal} from '@polysky-libs/uikit'
import {useTranslation} from 'contexts/Localization'
import useAuth from 'hooks/useAuth'
import React from 'react'
import styled from 'styled-components'

const UnlockButton = (props) => {
  const {t} = useTranslation()
  const {login, logout} = useAuth()
  const {onPresentConnectModal} = useWalletModal(login, logout)

  return (
    <BTN onClick={onPresentConnectModal} type="button" {...props}>
      {t('Unlock Wallet')}
    </BTN>
  )
}

export default UnlockButton

const BTN = styled.button`
  background: #0052ff;
  border: none;
  color: white;
  padding: 0.6rem 1.5rem;
  border-radius: 30px;
  width: min-content;
  white-space: nowrap;
  display: inline;
  font-weight: bold;
  cursor: pointer;
`
