import { CalculateIcon, CardHeader, CardRibbon, Flex, useModal } from '@polysky-libs/uikit'
import BigNumber from 'bignumber.js'
import ApyCalculatorModal from 'components/ApyCalculatorModal'
import TokenImage from 'components/TokenImage'
import UnlockButton from 'components/UnlockButton'
import { BASE_EXCHANGE_URL } from 'config'
import { FontSize } from 'constants/Theme'
import { useTranslation } from 'contexts/Localization'
import React, { useState } from 'react'
import { Pool } from 'state/types'
import styled from 'styled-components'
import { getAddress } from 'utils/addressHelpers'
import { BIG_ZERO } from 'utils/bigNumber'
import { getAprData } from 'views/Pools/helpers'
import AprRow from './AprRow'
import CardActions from './CardActions'
import ExpandedFooter from './CardFooter/ExpandedFooter'
import DepositRow from './DepositRow'
import { StyledCard, StyledCardInner } from './StyledCard'

const PoolCard: React.FC<{pool: Pool; account: string}> = ({pool, account}) => {
  const Wrapper = styled(CardHeader)<{isFinished?: boolean; background?: string}>`
    background: ${({isFinished, background, theme}) => (isFinished ? theme.colors.backgroundDisabled : '#ffffff')};
    border-radius: ${({theme}) => `${theme.radii.card} ${theme.radii.card} 0 0`};
  `
  const {sousId, stakingToken, earningToken, isFinished, userData, apr, earningTokenPrice, isAutoVault} = pool
  const performanceFee = 0
  const {t} = useTranslation()
  const stakedBalance = userData?.stakedBalance ? new BigNumber(userData.stakedBalance) : BIG_ZERO
  const accountHasStakedBalance = stakedBalance.gt(0)
  const [isExpanded, setIsExpanded] = useState(false)

  const {apr: earningsPercentageToDisplay, roundingDecimals, compoundFrequency} = getAprData(pool, performanceFee)
  const apyModalLink =
    stakingToken.address && `${BASE_EXCHANGE_URL}/swap?outputCurrency=${getAddress(stakingToken.address)}`

  const handleClicked = () => {
    setIsExpanded(!isExpanded)
  }

  const [onPresentApyModal] = useModal(
    <ApyCalculatorModal
      tokenPrice={earningTokenPrice}
      apr={apr}
      linkLabel={t('Get %symbol%', {symbol: stakingToken.symbol})}
      linkHref={apyModalLink || BASE_EXCHANGE_URL}
      earningTokenSymbol={earningToken.symbol}
      roundingDecimals={roundingDecimals}
      compoundFrequency={compoundFrequency}
      performanceFee={performanceFee}
    />,
  )

  return (
    <StyledCard
      isFinished={isFinished && sousId !== 0}
      ribbon={isFinished && <CardRibbon variantColor="textDisabled" text={t('Finished')} />}
      // onClick={handleClicked}
      onKeyDown={handleClicked}
      onKeyUp={handleClicked}
      onKeyPress={handleClicked}
    >
      <StyledCardInner>
        <Flex flexDirection="row">
          <div style={{width: '100%'}}>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start'}}>
              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'flex-start'}}>
                <span style={{marginRight: '8px'}}>
                  <TokenImage token={stakingToken} width={44} height={44} />
                </span>
                <div>
                  <H2>{`${stakingToken.symbol}`}</H2>
                  <AprRow pool={pool} />
                  <DepositRow pool={pool} />
                </div>
              </div>
              <span
                onClick={onPresentApyModal}
                onKeyDown={onPresentApyModal}
                onKeyUp={onPresentApyModal}
                tabIndex={0}
                onKeyPress={onPresentApyModal}
                role="button"
              >
                <CalculateIcon color="textSubtle" width="30px" />
              </span>
            </div>
            {account ? (
              <CardActions pool={pool} stakedBalance={stakedBalance} />
            ) : (
              <div
                className="teryiuytrertyuiop"
                style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}
              >
                <Span style={{display: 'block', marginRight: '10px'}}>{t('Start Earning')}</Span>
                <UnlockButton style={{display: 'block'}} />
              </div>
            )}
          </div>
        </Flex>
        <ButtonBox>
          <Button
            type="button"
            onClick={handleClicked}
            onKeyDown={handleClicked}
            onKeyUp={handleClicked}
            onKeyPress={handleClicked}
          >
            {isExpanded ? (
              <svg viewBox="0 0 24 24" color="primary" width="20px" className="open">
                <Path d="M8.11997 14.7101L12 10.8301L15.88 14.7101C16.27 15.1001 16.9 15.1001 17.29 14.7101C17.68 14.3201 17.68 13.6901 17.29 13.3001L12.7 8.7101C12.31 8.3201 11.68 8.3201 11.29 8.7101L6.69997 13.3001C6.30997 13.6901 6.30997 14.3201 6.69997 14.7101C7.08997 15.0901 7.72997 15.1001 8.11997 14.7101Z" />
              </svg>
            ) : (
              <svg viewBox="0 0 24 24" color="primary" width="20px" className="close">
                <Path d="M8.11997 9.29006L12 13.1701L15.88 9.29006C16.27 8.90006 16.9 8.90006 17.29 9.29006C17.68 9.68006 17.68 10.3101 17.29 10.7001L12.7 15.2901C12.31 15.6801 11.68 15.6801 11.29 15.2901L6.69997 10.7001C6.30997 10.3101 6.30997 9.68006 6.69997 9.29006C7.08997 8.91006 7.72997 8.90006 8.11997 9.29006Z" />
              </svg>
            )}
          </Button>
        </ButtonBox>
        {isExpanded && <ExpandedFooter pool={pool} account={account} />}
      </StyledCardInner>
    </StyledCard>
  )
}

export default PoolCard

const Button = styled.button`
  background: transparent;
  border: none;
  padding: 0;
  padding-left: 0.2rem;
  cursor: pointer;
`

const ButtonBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.5rem;
`

const H2 = styled.h2`
  color: ${(props) => props.theme.theme.PRIMARY_BACKGROUND_COLOR};
  font-size: ${FontSize.mobil}rem;

  @media (min-width: 768px) {
    font-size: ${FontSize.tab}rem;
  }
`

const Span = styled.span`
  color: ${(props) => props.theme.theme.PRIMARY_BACKGROUND_COLOR};
  font-size: ${FontSize.mobil}rem;

  @media (min-width: 768px) {
    font-size: ${FontSize.tab}rem;
  }
`
const Path = styled.path`
  fill: ${(props) => props.theme.theme.PRIMARY_BACKGROUND_COLOR};
  transition: fill 0.5s ease-in-out;
`
