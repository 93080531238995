import React from 'react'
import styled from 'styled-components'
import { Button, AutoRenewIcon, Skeleton } from '@polysky-libs/uikit'
import { useTranslation } from 'contexts/Localization'
import { useERC20 } from 'hooks/useContract'
import { getAddress } from 'utils/addressHelpers'
import { Pool } from 'state/types'
import { useApprovePool } from '../../../hooks/useApprove'

interface ApprovalActionProps {
  pool: Pool
  isLoading?: boolean
}

const ApprovalAction: React.FC<ApprovalActionProps> = ({ pool, isLoading = false }) => {
  const { sousId, stakingToken, earningToken } = pool
  const { t } = useTranslation()
  const stakingTokenContract = useERC20(stakingToken.address ? getAddress(stakingToken.address) : '')
  const { handleApprove, requestedApproval } = useApprovePool(stakingTokenContract, sousId, earningToken.symbol)

  return (
    <>
      {isLoading ? (
        <Skeleton width="100%" height="25px" />
      ) : (
        <Btn
          isLoading={requestedApproval}
          // endIcon={requestedApproval ? <AutoRenewIcon spin color="currentColor" /> : null}
          disabled={requestedApproval}
          onClick={handleApprove}
        >
          {t('Approve')}
        </Btn>
      )}
    </>
  )
}

export default ApprovalAction;

const Btn = styled.button<{isLoading: boolean}>`
color: white;
padding: 0.7rem 1.5rem;
border: none;
border-radius: 30px;
background-color: ${(props) => props.theme.theme.PRIMARY_BUTTON_COLOR_BLUE};
transition: background-color 0.5s ease-in-out;
cursor: pointer;

&:disabled{
  cursor: not-allowed;
}

`
