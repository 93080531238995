import { DEFAULT_META, getCustomMeta } from 'config/constants/meta'
import { useTranslation } from 'contexts/Localization'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useLocation } from 'react-router'
import { usePriceSiriusUsdc } from 'state/hooks'
import styled from 'styled-components'
import Container from './Container'

const StyledPage = styled(Container)`
 
  padding-right:8px;
  padding-left:8px;


`

const PageMeta = () => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const siriusPriceUsd = usePriceSiriusUsdc()
  const siriusPriceUsdDisplay = siriusPriceUsd.gt(0)
    ? `$${siriusPriceUsd.toNumber().toLocaleString(undefined, {
        minimumFractionDigits: 3,
        maximumFractionDigits: 3,
      })}`
    : ''

  const pageMeta = getCustomMeta(pathname, t) || {}
  const { title, description, image } = { ...DEFAULT_META, ...pageMeta }
  const pageTitle = siriusPriceUsdDisplay ? [title, siriusPriceUsdDisplay].join(' - ') : title

  return (
    <Helmet>
      <title>{pageTitle}</title>
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
    </Helmet>
  )
}

const Page: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ children, ...props }) => {
  return (
    <>
      <PageMeta />
      <StyledPage {...props}>{children}</StyledPage>
    </>
  )
}

export default Page
