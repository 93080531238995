import darkBg from '../assets/images/g.png'
import lightBg from '../assets/images/lightbg.png'

type TdarkTheme = {
  mode: string
  PRIMARY_BACKGROUND_COLOR: string
  PRIMARY_BACKGROUND_COLOR_1: string
  PRIMARY_TEXT_COLOR: string
  PRIMARY_BUTTON_COLOR: string
  PRIMARY_BUTTON_COLOR_RED: string
  PRIMARY_BUTTON_COLOR_GREEN: string
  PRIMARY_BUTTON_COLOR_YELLOW: string
  PRIMARY_BUTTON_COLOR_BLUE: string
  PRIMARY_BUTTON_COLOR_GREY: string
  PRIMARY_BUTTON_COLOR_SKYBLUE: string
  PRIMARY_BUTTON_COLOR_NEARWHITE: string
  PRIMARY_BUTTON_TEXT_COLOR_WHITE: string
  BORDER_COLOR: string
  TABLE_BORDER_COLOR: string
  SHADOW_COLOR: string
  LIGHT_MODE: string
  HAMBURGER_BG_COLORR: string
  HAMBURGER_LINES_COLOR: string
  DROP_SHADOW_SIDENAV: string
  BACKGROUND_IMAGE: string
  backgroundNearWhite: string
}
export const darkTheme: TdarkTheme = {
  mode: 'dark',
  PRIMARY_BACKGROUND_COLOR: '#030408',
  PRIMARY_BACKGROUND_COLOR_1: '#030408',
  PRIMARY_TEXT_COLOR: '#FFFFFF',
  PRIMARY_BUTTON_COLOR: '#0052FF',
  PRIMARY_BUTTON_COLOR_RED: '#FF4423',
  PRIMARY_BUTTON_COLOR_GREEN: '#64D637',
  PRIMARY_BUTTON_COLOR_YELLOW: '#FFBB0F',
  PRIMARY_BUTTON_COLOR_BLUE: '#6378FF',
  PRIMARY_BUTTON_COLOR_GREY: '#7B7C7D',
  PRIMARY_BUTTON_COLOR_SKYBLUE: '#B4CAEC',
  PRIMARY_BUTTON_COLOR_NEARWHITE: '#E0EDFD',
  PRIMARY_BUTTON_TEXT_COLOR_WHITE: '#fff',
  BORDER_COLOR: '',
  TABLE_BORDER_COLOR: '1px solid rgba(112, 112, 112, 29%)',
  SHADOW_COLOR: '',
  LIGHT_MODE: '#0052FF',
  HAMBURGER_BG_COLORR: '#0052FF',
  HAMBURGER_LINES_COLOR: '#FFFFFF',
  DROP_SHADOW_SIDENAV: '0 10px 64px rgba(0,0,0, 92%)',
  BACKGROUND_IMAGE: darkBg,
  backgroundNearWhite: '#151A1A',
}

type TlightTheme = {
  mode: string
  PRIMARY_BACKGROUND_COLOR: string
  PRIMARY_BACKGROUND_COLOR_1: string
  PRIMARY_TEXT_COLOR: string
  PRIMARY_BUTTON_COLOR: string
  PRIMARY_BUTTON_COLOR_RED: string
  PRIMARY_BUTTON_COLOR_GREEN: string
  PRIMARY_BUTTON_COLOR_YELLOW: string
  PRIMARY_BUTTON_COLOR_BLUE: string
  PRIMARY_BUTTON_COLOR_GREY: string
  PRIMARY_BUTTON_COLOR_SKYBLUE: string
  PRIMARY_BUTTON_COLOR_NEARWHITE: string
  PRIMARY_BUTTON_TEXT_COLOR_WHITE: string
  BORDER_COLOR: string
  TABLE_BORDER_COLOR: string
  SHADOW_COLOR: string
  LIGHT_MODE: string
  HAMBURGER_BG_COLORR: string
  HAMBURGER_LINES_COLOR: string
  DROP_SHADOW_SIDENAV: string
  BACKGROUND_IMAGE: string
  backgroundNearWhite: string
}
export const ligthTheme: TlightTheme = {
  mode: 'light',
  PRIMARY_BACKGROUND_COLOR: '#FFFFFF',
  PRIMARY_BACKGROUND_COLOR_1: '#FFFFFF',
  PRIMARY_TEXT_COLOR: '#000000',
  PRIMARY_BUTTON_COLOR: '#0052FF',
  PRIMARY_BUTTON_COLOR_RED: '#FF4423',
  PRIMARY_BUTTON_COLOR_GREEN: '#64D637',
  PRIMARY_BUTTON_COLOR_YELLOW: '#FFBB0F',
  PRIMARY_BUTTON_COLOR_BLUE: '#6378FF',
  PRIMARY_BUTTON_COLOR_GREY: '#707070',
  PRIMARY_BUTTON_COLOR_SKYBLUE: '#B4CAEC',
  PRIMARY_BUTTON_COLOR_NEARWHITE: '#E0EDFD',
  PRIMARY_BUTTON_TEXT_COLOR_WHITE: '#fff',
  BORDER_COLOR: '',
  TABLE_BORDER_COLOR: '1px solid #ddd',
  SHADOW_COLOR: '',
  LIGHT_MODE: '#0052FF',
  HAMBURGER_BG_COLORR: '#FFFFFF',
  HAMBURGER_LINES_COLOR: '#0052FF',
  DROP_SHADOW_SIDENAV: '0px 10px 64px rgba(0, 0, 0, 16%)',
  BACKGROUND_IMAGE: lightBg,
  backgroundNearWhite: '#F2F3F3',
}

export const FontSize = {
  mobil: 0.75,
  tab: 0.875,
  mobiButton: 0.5625,
}
