import {Button, Flex, HelpIcon, LinkExternal, MetamaskIcon, Skeleton, useTooltip} from '@polysky-libs/uikit'
import Balance from 'components/Balance'
import {BASE_POLYGON_SCAN_URL, BASE_URL} from 'config'
import {FontSize} from 'constants/Theme'
import {useTranslation} from 'contexts/Localization'
import React from 'react'
import {useBlock, useSiriusVault} from 'state/hooks'
import {Pool} from 'state/types'
import styled from 'styled-components'
import {getAddress} from 'utils/addressHelpers'
import {getBalanceNumber} from 'utils/formatBalance'
import {registerToken} from 'utils/wallet'
import {getPoolBlockInfo} from 'views/Pools/helpers'

interface ExpandedFooterProps {
  pool: Pool
  account: string
}

const ExpandedWrapper = styled(Flex)`
  margin-top: 1.5rem;
  border-top: 1px solid rgba(112, 112, 112, 0.17);
  padding-top: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  svg {
    height: 14px;
    width: 14px;
  }
`

const ExpandedFooter: React.FC<ExpandedFooterProps> = ({pool, account}) => {
  const {t} = useTranslation()
  const {currentBlock} = useBlock()
  const {
    totalCakeInVault,
    fees: {performanceFee},
  } = useSiriusVault()

  const {
    stakingToken,
    earningToken,
    totalStaked,
    startBlock,
    endBlock,
    stakingLimit,
    contractAddress,
    sousId,
    isAutoVault,
  } = pool

  const tokenAddress = earningToken.address ? getAddress(earningToken.address) : ''
  const sTokenAddress = stakingToken.address ? getAddress(stakingToken.address) : ''
  const poolContractAddress = getAddress(contractAddress)
  const imageSrc = `${BASE_URL}/images/tokens/${tokenAddress}.png`
  const isMetaMaskInScope = !!window.ethereum?.isMetaMask
  const isManualCakePool = sousId === 0

  const {shouldShowBlockCountdown, blocksUntilStart, blocksRemaining, hasPoolStarted, blocksToDisplay} =
    getPoolBlockInfo(pool, currentBlock)

  const {targetRef, tooltip, tooltipVisible} = useTooltip(
    t('Subtracted automatically from each yield harvest and burned.'),
    {placement: 'bottom-start'},
  )

  const getTotalStakedBalance = () => {
    return getBalanceNumber(totalStaked, stakingToken.decimals)
  }

  const {
    targetRef: totalStakedTargetRef,
    tooltip: totalStakedTooltip,
    tooltipVisible: totalStakedTooltipVisible,
  } = useTooltip(t('Total amount of %symbol% staked in this pool', {symbol: stakingToken.symbol}), {
    placement: 'bottom',
  })

  return (
    <ExpandedWrapper flexDirection="column">
      <Flex mb="2px" justifyContent="space-between" alignItems="center">
        <Text>{t('Total staked')}:</Text>
        <Flex alignItems="flex-start">
          {totalStaked && totalStaked.gte(0) ? (
            <>
              <Balance small value={getTotalStakedBalance()} decimals={0} unit={` ${stakingToken.symbol}`} />
              <span ref={totalStakedTargetRef}>
                <HelpIcon color="textSubtle" width="20px" ml="6px" mt="4px" />
              </span>
            </>
          ) : (
            <Skeleton width="90px" height="21px" />
          )}
          {totalStakedTooltipVisible && totalStakedTooltip}
        </Flex>
      </Flex>

      <Flex mb="2px">
        <LInkHREF href={`https://polygonscan.com/token/${getAddress(stakingToken.address)}`} bold={false} small>
          {t('See Token Info')}
        </LInkHREF>
      </Flex>
      <Flex mb="2px">
        <LInkHREF href={earningToken.projectLink} bold={false} small>
          {t('View Project Site')}
        </LInkHREF>
      </Flex>
      {poolContractAddress && (
        <Flex mb="2px">
          <LInkHREF href={`${BASE_POLYGON_SCAN_URL}/address/${poolContractAddress}`} bold={false} small>
            {t('View Contract')}
          </LInkHREF>
        </Flex>
      )}
      {account && isMetaMaskInScope && tokenAddress && (
        <Flex>
          <Button
            variant="text"
            p="0"
            height="auto"
            onClick={() => registerToken(sTokenAddress, stakingToken.symbol, stakingToken.decimals, imageSrc)}
          >
            <Text>{t('Add to Metamask')}</Text>
            <MetamaskIcon ml="4px" />
          </Button>
        </Flex>
      )}
    </ExpandedWrapper>
  )
}

export default React.memo(ExpandedFooter)

const Text = styled.p`
  color: ${(props) => props.theme.theme.PRIMARY_BACKGROUND_COLOR};
  font-size: ${FontSize.mobil}rem;

  @media (min-width: 768px) {
    font-size: ${FontSize.tab}rem;
  }
`

const LInkHREF = styled(LinkExternal)`
  color: ${(props) => props.theme.theme.PRIMARY_BACKGROUND_COLOR};
  font-size: ${FontSize.mobil}rem;

  @media (min-width: 768px) {
    font-size: ${FontSize.tab}rem;
  }
`
